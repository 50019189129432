import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {v1URL} from '../../../shared/constants/urls.constants';
import {CommonService} from "../../../shared/services/common.service";
import {HttpHeaders} from "@angular/common/http";
import {FlatTreeControl} from '@angular/cdk/tree';

@Injectable({
  providedIn: 'root',
})

export class AiAssistantService {
  selectedReport: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private commonServices: CommonService,
  ) {
  }

  getAnalytics(payload: any): Observable<any> {
    const {method, url} = v1URL.login;

    return this.commonServices.callAPI(
      method,
      url,
      payload,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      }
    );
  }
}
