<div
  class="sidenav-heading flex flex-row items-center justify-center"
>
  AI Assistant
</div>
<div class="sidenav-content flex flex-col">
  <div class="sidenav-content">
    <mat-tree
      [dataSource]="dataSource"
      [treeControl]="treeControl"
      class="bg-transparent text-white"
    >
      <!-- This is the tree node template for leaf nodes -->
      <mat-tree-node
        *matTreeNodeDef="let node"
        [class.selected]="selectedReport?.label === node.label"
        matTreeNodePadding
      >
        <div
          (click)="setSelectedReport(node)"
          class="flex flex-row gap-[1rem] cursor-pointer items-center justify-start px-[2rem] text-[1.2rem]"
        >
          <app-icon
            icon="bar_chart"
          />
          <div>
            {{ node.label }}
          </div>
        </div>
      </mat-tree-node>
      <!-- This is the tree node template for expandable nodes -->
      <mat-tree-node
        *matTreeNodeDef="let node;when: hasChild"
        matTreeNodePadding
      >
        <div
          class="flex flex-row gap-[1rem] px-[2rem] cursor-pointer items-center justify-start text-[1.2rem]"
          matTreeNodeToggle
        >
          <app-icon
            [icon]="treeControl.isExpanded(node) ? 'folder_open' : 'folder'"
            class="mat-icon-rtl-mirror"
          />
          <div>
            {{ node.label }}
          </div>
        </div>
      </mat-tree-node>
    </mat-tree>
  </div>
</div>
