<div class="page-wrapper flex flex-row w-full h-full xs:flex-col sm:flex-col md:flex-row">
  <div class="ai-assistant h-full
    xs:w-full md:w-[35%] lg:w-[40%]
    flex flex-row
    justify-around items-start
    p-[2rem]
    gap-[2rem]
    xs:ml-0 sm:ml-0 md:ml-[4rem] lg:ml-[4rem] xl:ml-[4rem]
    "
  >
    <app-global-ai-assistant class="w-full h-full"/>
  </div>
  <div
    class="menu-container
    xs:w-full md:w-[65%] lg:w-[60%]
    flex flex-row
    justify-around items-start
    p-[2rem]
    gap-[2rem]
    min-h-fit
    "
  >
    <div
      *ngIf="libraryMenu"
      class="library-menu-panel xs:mb-[2rem] sm:w-[50%] flex flex-col justify-start items-center"
    >
      <div class="menu-heading">
        Library
      </div>
      <div class="flex flex-row justify-center">

        <div class="max-w-[33rem] flex flex-row flex-wrap gap-[3rem] justify-center items-start sm:justify-start">
          <div
            *ngFor="let menuItem of libraryMenu"
            class="menu-item relative"
          >
            <a
              [routerLink]="'..'+menuItem.path"
            >
              <app-image
                [url]="menuItem.image"
                class="menu-image"
              />
              <div class="overlay library-overlay">
              </div>
              <div class="overlay text-overlay flex justify-center items-center">
                {{ menuItem.name }}
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="administrationMenu"
      class="administration-menu-panel sm:w-[50%] flex flex-col justify-start items-center"
    >
      <div class="menu-heading">
        Administration
      </div>
      <div class="flex flex-row justify-center">

        <div class="max-w-[33rem] flex flex-row flex-wrap gap-[3rem] justify-center items-start sm:justify-start">
          <div
            *ngFor="let menuItem of administrationMenu"
            class="menu-item"
          >
            <a
              [routerLink]="'..'+menuItem.path"
            >
              <app-image
                [url]="menuItem.image"
                class="menu-image"
              />
              <div class="overlay administration-overlay">
              </div>
              <div class="overlay text-overlay flex justify-center items-center">
                {{ menuItem.name }}
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
