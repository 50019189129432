import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ActionReducer, MetaReducer, StoreModule} from "@ngrx/store";
import {localStorageSync} from "ngrx-store-localstorage";
import {SharedModule} from "./modules/shared/shared.module";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {EffectsModule} from "@ngrx/effects";
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import {ApiInterceptor} from "./modules/shared/services/api.interceptor";
import {
  SuperAdminModule
} from "./modules/role-modules/super-admin/super-admin.module";


export function sessionStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [
      'school-voices.shared',
      'school-voices.super-admin',
      'school-voices.client-admin',
      'school-voices.client-user',
      'school-voices.login',
      'school-voices.reports',
      'school-voices.survey-versions',
      'school-voices.survey',
      'school-voices.manage-schools-of-client',
      'school-voices.manage-schools-of-user',
      'school-voices.downloads',
      'school-voices.clients-list',
      'school-voices.client-details',
      'school-voices.schools-list',
      'school-voices.school-details',
      'school-voices.users-list',
      'school-voices.user-details',
      'school-voices.analytics',
    ],
    rehydrate: true,
    storage: sessionStorage
  })(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [sessionStorageSyncReducer];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    StoreModule.forRoot({}, {metaReducers}),
    EffectsModule.forRoot([]),
    NgxSkeletonLoaderModule.forRoot({animation: 'pulse'}),
    NgxMatSelectSearchModule,

    SharedModule,
    SuperAdminModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
