import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from "../../shared/shared.module";
import {StoreModule} from "@ngrx/store";
import {reducer} from "./core/super-admin.reducer";
import {EffectsModule} from "@ngrx/effects";
import {SuperAdminEffects} from "./core/super-admin.effects";
import {SuperAdminRoutingModule} from "./super-admin.routing";
import {AiAssistantModule} from '../../page-modules/ai-assistant/ai-assistant.module';



@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    SharedModule,
    SuperAdminRoutingModule,
    AiAssistantModule,
    StoreModule.forFeature('school-voices.super-admin', reducer),
    EffectsModule.forFeature(
      [SuperAdminEffects]
    ),
  ]
})
export class SuperAdminModule { }
