import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {Action, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {AiAssistantService} from './ai-assistant.service';
import * as fromRoot from '../../../../state/app.state';
import {CommonService} from '../../../shared/services/common.service';
import {ActionModel} from '../../../../models/action.model';


@Injectable()
export class AiAssistantEffects {


  constructor(
    private store: Store<fromRoot.State>,
    private analyticsService: AiAssistantService,
    private actions: Actions,
    private router: Router,
    private commonService: CommonService
  ) {
  }
}
