import {ChangeDetectorRef, Component} from '@angular/core';
import {Subject, takeUntil} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../../state/app.state';
import {CommonService} from '../../../../shared/services/common.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {AiAssistantService} from '../../core/ai-assistant.service';

@Component({
  selector: 'app-ai-assistant-sidenav',
  templateUrl: './ai-assistant-sidenav.component.html',
  styleUrls: ['./ai-assistant-sidenav.component.css']
})
export class AiAssistantSidenavComponent {
  private readonly onDestroy: Subject<any> = new Subject<any>();

  private _transformer = (node: any, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      label: node.label,
      level: level,
      data: node
    };
  };

  treeControl = new FlatTreeControl<any>(
    node => node.level,
    node => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  selectedReport:any = {
    'label': 'Partner Report Card Insights'
  }

  constructor(
    private store: Store<fromRoot.State>,
    private commonService: CommonService,
    private aiAssistantService: AiAssistantService,
  ) {}

  ngOnInit() {
    // Open the first report by default
    this.aiAssistantService.selectedReport.next(this.selectedReport);
    this.dataSource.data = [
      {
        'label': 'School Insights',
        'children': [
          {
            'label': 'Partner Report Card Insights'
          }
        ]
      }
    ];

    // Expand the first node
    this.treeControl.expand(this.treeControl.dataNodes[0]);
  }

  hasChild = (_: number, node: any) => node.expandable;

  setSelectedReport = (report: FlatTreeControl<any>) => {
    this.selectedReport = report;
    this.aiAssistantService.selectedReport.next(this.selectedReport);
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
