import {createReducer, on} from '@ngrx/store';
import {
  ClearState,
  SetAnalyticsQA
} from './ai-assistant.actions';

// State for this feature (User)
export interface AnalyticsState {
  loading: boolean;
  questions: any[]
}

const initialState: AnalyticsState = {
  loading: false,
  questions: [],
};

export const reducer = createReducer(
  initialState,

  // CLEAR STATE
  on(ClearState, (state) => initialState),

  on(SetAnalyticsQA, (state, props) => ({
    ...state,
    loading: false,
    questions: [
      ...state.questions,
      props.payload
    ],
  }))
);
