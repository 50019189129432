import { Component } from '@angular/core';
import {Subject, takeUntil} from 'rxjs';
import {AiAssistantService} from '../core/ai-assistant.service';

@Component({
  selector: 'app-ai-assistant-screen',
  templateUrl: './ai-assistant-screen.component.html',
  styleUrls: ['./ai-assistant-screen.component.css']
})
export class AiAssistantScreenComponent {
  private readonly onDestroy: Subject<any> = new Subject<any>();

  selectedReport: any;

  constructor(
    private aiAssistantService: AiAssistantService,
  ) {
  }

  ngOnInit() {
    this.aiAssistantService.selectedReport
      .pipe(takeUntil(this.onDestroy))
      .subscribe(report => {
        this.selectedReport = report;
      })
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
