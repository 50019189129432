<div class="wrapper">
  <ng-container *ngIf="questions.length">
    <div class="chat-history-section"  #chatHistory>
      <ng-container *ngFor="let q of questions">
        <div class="chat-bubble user">
          <div class="bubble">{{q.question}}</div>
        </div>
        <div class="chat-bubble bot">
          <div class="bubble" [innerHTML]="q.answer | markdownToHtml"></div>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <div class="question-section flex gap-[1.5rem]">
    <div class="question-section flex-grow">
      <form [formGroup]="form">
        <app-text-area-input
          [parentFormGroup]="form"
          class="w-full"
          controlName="question"
          placeholder="Ask me something"
          [rows]="2"
        />
      </form>
    </div>
    <div class="action-section flex items-center">
      <app-primary-button (handleClick)="askQuestion()">
        Submit
      </app-primary-button>
    </div>
  </div>
</div>
