/* NgRx */
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AnalyticsState} from './ai-assistant.reducer';

// Selector functions
const stateData =
  createFeatureSelector<AnalyticsState>('school-voices.analytics');

export const questions = createSelector(
  stateData,
  (state: AnalyticsState) => state.questions
);
