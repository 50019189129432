import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'markdownToHtml'
})
export class MarkdownToHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): SafeHtml {
    let html = value
      // Headers (### Heading 3, ## Heading 2, # Heading 1)
      .replace(/^###### (.*$)/gm, '<h6>$1</h6>')
      .replace(/^##### (.*$)/gm, '<h5>$1</h5>')
      .replace(/^#### (.*$)/gm, '<h4>$1</h4>')
      .replace(/^### (.*$)/gm, '<h3>$1</h3>')
      .replace(/^## (.*$)/gm, '<h2>$1</h2>')
      .replace(/^# (.*$)/gm, '<h1>$1</h1>')

      // Bold (**bold** or __bold__)
      .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
      .replace(/__(.*?)__/g, '<b>$1</b>')

      // Italics (*italic* or _italic_)
      .replace(/\*(.*?)\*/g, '<i>$1</i>')
      .replace(/_(.*?)_/g, '<i>$1</i>')

      // Strikethrough (~~strikethrough~~)
      .replace(/~~(.*?)~~/g, '<del>$1</del>')

      // Inline Code (`code`)
      .replace(/`([^`]+)`/g, '<code>$1</code>')

      // Code Blocks (```code```)
      .replace(/```([^`]+)```/g, '<pre><code>$1</code></pre>')

      // Blockquotes (> Quote)
      .replace(/^> (.*$)/gm, '<blockquote>$1</blockquote>')

      // Ordered Lists (1. Item)
      .replace(/^\d+\.\s(.*$)/gm, '<li>$1</li>')
      .replace(/(<li>.*<\/li>)/g, '<ol>$1</ol>')

      // Unordered Lists (- Item or * Item)
      .replace(/^- (.*$)/gm, '<li>$1</li>')
      .replace(/\n/g, '<br>')
      .replace(/---/g, '<hr>')


      // Horizontal Rule (--- or ***)
      .replace(/^-{3,}$/gm, '<hr>')
      .replace(/^\*{3,}$/gm, '<hr>')

      // Links [text](url)
      .replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2" target="_blank">$1</a>')

      // Images ![alt](url)
      .replace(/!\[([^\]]+)\]\(([^)]+)\)/g, '<img src="$2" alt="$1">');
    if (!value) return '';

    // Convert Markdown to HTML using regex

    // Sanitize the HTML
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
