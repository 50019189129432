import {Component, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../../state/app.state';
import {Subject, takeUntil} from 'rxjs';
import {questions} from '../../core/ai-assistant.selectors';
import {SetAnalyticsQA} from '../../core/ai-assistant.actions';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {v4 as uuid} from 'uuid';
import {CommonService} from '../../../../shared/services/common.service';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})
export class QuestionComponent implements AfterViewInit {
  private readonly onDestroy: Subject<any> = new Subject<any>();

  form = new FormGroup({
    question: new FormControl(null)
  });

  questions = [];
  sessionId = uuid();

  @ViewChild('chatHistory') private chatHistory: ElementRef;

  constructor(
    private store: Store<fromRoot.State>,
    private backendHandler: HttpBackend,
    private commonService: CommonService,
  ) {}

  ngOnInit() {
    this.sessionId = uuid();
    this.store.select(questions)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        this.questions = data;
        setTimeout(() => {
          this.scrollToBottom();
        }, 10);
      });
  }

  ngAfterViewInit() {
    this.scrollToBottom();
  }

  askQuestion() {
    this.commonService.startLoading();

    const question = this.form.controls.question.value;

    this.form.controls.question.setValue(null);

    const http = new HttpClient(this.backendHandler);
    http.post(`https://schoolvoices360.app.n8n.cloud/webhook/989f5fb1-af16-4682-be22-dbe383fc02de/chat`, {
        action: 'sendMessage',
        chatInput: question,
        sessionId: this.sessionId,
      })
      .subscribe(
        (data) => {
          this.store.dispatch(SetAnalyticsQA({
            payload: {
              question: question,
              answer: data['output']
            }
          }));

          this.commonService.stopLoading();
        },
        (error) => {
          this.commonService.notification('Unable to fetch answer', 'danger');
          this.commonService.stopLoading();
        });
  }

  private scrollToBottom(): void {
    if (this.chatHistory) {
      try {
        this.chatHistory.nativeElement.scrollTop = this.chatHistory.nativeElement.scrollHeight;
      } catch(err) {
        console.error('Scroll to bottom failed', err);
      }
    }
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
