<div class="wrapper">
  <div class="chat-history-section" #chatHistory>
    <div class="chat-history" *ngIf="questions.length; else noQuestions">
      <ng-container *ngFor="let q of questions">
        <div class="chat-bubble user">
          <div class="bubble">{{q.question}}</div>
        </div>
        <div class="chat-bubble bot">
          <div class="bubble" [innerHTML]="q.answer | markdownToHtml"></div>
        </div>
      </ng-container>
    </div>
    <ng-template #noQuestions>
      <div class="no-questions">
        Ask something to SchoolVoices360
      </div>
    </ng-template>
  </div>
  <div class="question-section flex gap-[1.5rem]">
    <div class="question-section flex-grow">
      <form [formGroup]="form">
        <app-text-area-input
          [parentFormGroup]="form"
          class="w-full"
          controlName="question"
          placeholder="Ask me something"
          [rows]="2"
        />
      </form>
    </div>
    <div class="action-section flex items-center">
      <app-primary-button (handleClick)="askQuestion()">
        Submit
      </app-primary-button>
    </div>
  </div>
</div>
