import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AiAssistantSidenavComponent } from './components/ai-assistant-sidenav/ai-assistant-sidenav.component';
import { AiAssistantScreenComponent } from './screen/ai-assistant-screen.component';
import {SharedModule} from '../../shared/shared.module';
import {AiAssistantRouting} from './ai-assistant.routing';
import {ReactiveFormsModule} from '@angular/forms';
import {StoreModule} from '@ngrx/store';
import {reducer} from './core/ai-assistant.reducer';
import {EffectsModule} from '@ngrx/effects';
import {AiAssistantEffects} from './core/ai-assistant.effects';
import {QuestionComponent} from './components/question/question.component';



@NgModule({
  declarations: [
    AiAssistantSidenavComponent,
    AiAssistantScreenComponent,
    QuestionComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    AiAssistantRouting,
    ReactiveFormsModule,
    StoreModule.forFeature('school-voices.analytics', reducer),
    EffectsModule.forFeature(
      [AiAssistantEffects]
    )
  ]
})
export class AiAssistantModule { }
