import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {
  InternalPageNotFoundComponent
} from "../../shared/pages/page-not-found/internal-page-not-found/internal-page-not-found.component";
import {AiAssistantSidenavComponent} from './components/ai-assistant-sidenav/ai-assistant-sidenav.component';
import {AiAssistantScreenComponent} from './screen/ai-assistant-screen.component';


const routes: Routes = [
  {
    path: '',
    outlet: 'sidenav',
    component: AiAssistantSidenavComponent
  },
  {
    path: '',
    outlet: 'content',
    component: AiAssistantScreenComponent
  },
  {
    path: '**',
    component: InternalPageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AiAssistantRouting {}
